import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../utils/mediaQueries"

const BroadCumb = styled.p`
  font-size: 1rem;
  color: ${props => (props.final ? "#66afe9" : "#555")};
  font-weight: ${props => (props.final ? "800" : "300")};
  padding: 1rem 0.3rem;
  text-transform: capitalize;
  text-decoration: none;
  @media ${device.tablet} {
    font-size: 0.6rem;
  }
`
const Container = styled.div`
  display: flex;
  margin: 0px;
  padding: 0px;
  justify-content: center;
`
const BrodcumbSet = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const BreadLink = styled(Link)`
  text-decoration: none;
`

export const Breadcrumbs = () => {
  const [urls, setUrls] = useState([])
  useEffect(() => {
    setUrls(window.location.href.split("/").slice(2))
  }, [])
  return (
    <Container>
      {urls.map((u, i) => {
        console.log(`i on ${i} ja lenght on ${urls.length - 2}`)
        console.log("Urlit", urls)
        const isFinal = i === urls.length - 2
        if (i === 0)
          return (
            <BrodcumbSet key={i}>
              <BreadLink to="/">
                <BroadCumb>Etusivu</BroadCumb>
              </BreadLink>
              <BroadCumb>></BroadCumb>
            </BrodcumbSet>
          )
        if (i === 1)
          return (
            <BrodcumbSet key={i}>
              <BreadLink to={`/${u}`}>
                <BroadCumb final={isFinal}>{u}</BroadCumb>
              </BreadLink>
              {!isFinal && <BroadCumb>></BroadCumb>}
            </BrodcumbSet>
          )
        if (i === 2)
          return (
            <BrodcumbSet key={i}>
              <BreadLink to={`/${urls[1]}/${u}`}>
                <BroadCumb final={isFinal}>{u}</BroadCumb>
              </BreadLink>
              {!isFinal && <BroadCumb>></BroadCumb>}
            </BrodcumbSet>
          )
        return ""
      })}
    </Container>
  )
}
